<script>
export default {
  name: "Thanks",
  methods: {
    go_next() {
      var returnComponent = localStorage.getItem("ReturnComponent");
      if (returnComponent) {
        var ob = { name: returnComponent };
        this.$router.push(ob);
      }
      this.$router.push("/");
    },
  },
};
</script>

<template>
  <div id="Thanks">
    <div class="container">
      <div id="content" class="row justify-content-center">
        <div class="col-10">
          <div class="row justify-content-center">
            <div class="col-8 col-md-6">
              Thanks for completing registration.
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-8 col-md-6">
              Check your inbox for an email to complete the registration
              process.
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-8 col-md-6">
              Be sure to check your spam folder as well.
            </div>
          </div>

          <div class="row justify-content-center">
            <div class="col text-center">
              <button class="btn btn-primary" @click="go_next">Go Home</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
#Thanks {
  padding-top: 20px;
  padding-bottom: 20px;
  background: rgb(246, 246, 246);
}
#content {
  box-shadow: rgb(227, 228, 231) 0px 2px 0px 0px;
  border: 1px solid rgb(241, 241, 241);
  background: white;
  padding-top: 10px;
  padding-bottom: 10px;

  .row {
    margin-bottom: 10px;
  }
}
</style>
